import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #000;
  color: #888;
  font-size: 11px;
  bottom: 0;
  width: 100vw;
  padding: 20px 0 20px 20px;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
`;

function Footer() {
  return (
    <FooterWrapper>
      <p>&copy; 2024 Splash, Bannangco Team</p>
      <p>반낭코 &nbsp; 사업자등록번호: 285-23-02044 &nbsp; 대표자: 심윤보 &nbsp; Tel: 010-4210-5104 &nbsp; <br/> E-Mail: bannangko@gmail.com &nbsp; Location: 경기도 수원시 장안구 서부로 2167번길 28, B04호(율전동)</p>
      <p>현재 플랫폼 리뉴얼 공사 중입니다. 2025년 1월 중순 새롭게 만나요!</p>
    </FooterWrapper>
  );
}

export default Footer;
