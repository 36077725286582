import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';

const MainContainer = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  font-family: 'Noto Sans KR', sans-serif;
  padding: 20px;
  text-align: center;
`;

const Icon = styled.img`
  width: 120px;
  height: auto;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.6;
  max-width: 500px;
  color: #555;
  margin-bottom: 40px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #1b56ed;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

function RenewalMain() {
  return (
    <>
      <Helmet>
        <title>플랫폼 리뉴얼 공사 중 - Splash</title>
        <meta name="description" content="가족 액티비티 플랫폼 Splash가 새롭게 태어납니다! 조금만 기다려주세요." />
        <meta name="keywords" content="가족 여행, 리뉴얼, Splash, 플랫폼 공사중" />
        <link rel="canonical" href="/" />
      </Helmet>
      <MainContainer>
        <Icon src={`${process.env.PUBLIC_URL}/img/aboutus/under_construction.png`} alt="공사중 아이콘" />
        <Title>플랫폼 리뉴얼 공사 중</Title>
        <Description>
          Splash 가족 액티비티 플랫폼을 더 나은 모습으로 만나보실 수 있도록 준비하고 있습니다. <br/>
          새로운 가족여행 및 액티비티 경험을 제공할 수 있도록 열심히 작업 중이니, <br/>
          조금만 기다려주세요! <br/><br/>
          회사에 대한 더 자세한 정보는 <StyledLink to="/about-us">회사 소개 페이지</StyledLink>에서 확인하실 수 있습니다.
        </Description>
      </MainContainer>
    </>
  );
}

export default RenewalMain;
