import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet-async";

const AboutUsWrapper = styled.div`
  width: 100%;
  font-family: 'Noto Sans KR', sans-serif;
  color: #333;
  line-height: 1.7;
`;

/* --------------
   Top Section (회사 소개)
   -------------- */
const TopSection = styled.section`
  position: relative;
  background: url('/img/aboutus/aboutus_bg.png') no-repeat center center/cover;
  &:before {
    content: "";
    position: absolute;
    top:0; left:0; right:0; bottom:0;
    background: rgba(0,0,0,0.6);
  }
  padding: 100px 20px 60px 20px;
  color: #fff;
`;

const TopContent = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  z-index: 1;
`;

const SectionTitle = styled.h1`
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  
  &::before {
    content: "—";
    display: inline-block;
    font-size: 40px;
    font-weight: 300;
    color: #fff;
    margin-right: 10px;
    transform: translateY(-2px);
  }
`;

const TopParagraph = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

/* Blue Info Section with centered text */
const BlueInfoSection = styled.div`
  background: #1b56ed;
  padding: 30px 20px;
`;

const BlueInfoContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

/* Base InfoBox, centered text */
const InfoBoxBase = styled.div`
  flex: 0 0 45%;
  color: #fff;
  margin-bottom: 40px;
  text-align: center; /* center text horizontally */
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    display: inline-flex; /* inline-flex to center icon and text together */
    align-items: center;
    justify-content: center;
  }

  h3::before {
    content: "";
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 0;
  }
`;

/* Separate InfoBoxes for different icons */
const ParentInfoBox = styled(InfoBoxBase)`
  h3::before {
    background: url('/img/aboutus/aboutus_icon1.png') no-repeat center center/contain;
  }
`;

const ChildInfoBox = styled(InfoBoxBase)`
  h3::before {
    background: url('/img/aboutus/aboutus_icon2.png') no-repeat center center/contain;
  }
`;

/* --------------
   Vision & Goals Section
   Desired Structure:
   - Entire section has light grey background (#f8f9fb)
   - Inside, a container with Vision title and a white box for the Vision part
   - Goals and strategies directly on the grey background below the vision box
   -------------- */

const VisionGoalsSection = styled.section`
  background: #f8f9fb;
  padding: 100px 20px 60px 20px;
`;

const InnerContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const VisionTitle = styled.h2`
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  color: #000;

  &::before {
    content: "—";
    display: inline-block;
    font-size: 40px;
    font-weight: 300;
    color: #000;
    margin-right: 10px;
    transform: translateY(-2px);
  }
`;

const WhiteVisionContainer = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const HighlightTitle = styled.h3`
  font-size: 32px;
  color: #0056b3;
  font-weight: 700;
  margin-bottom: 10px;
`;

const VisionParagraph = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.7;
`;

const GoalsTitle = styled.h4`
  font-size: 22px;
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 30px;
`;

const GoalsParagraph = styled.p`
  font-size: 16px;
  margin-bottom: 40px;
  color: #333;
`;

const StrategyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const StrategyBox = styled.div`
  background: #fff;
  flex: 1 1 calc(33.333% - 20px);
  min-width: 200px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  text-align: center;

  @media (max-width: 600px) {
    flex: 1 1 100%;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #555;
    margin:0;
  }
`;

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>회사 소개 - Splash</title>
        <meta name="description" content="Splash는 어린 자녀를 둔 가족들에게 더욱 편리하고 가치있는 여행 경험을 제공하는 가족 중심 종합 액티비티 플랫폼입니다." />
        <meta name="keywords" content="Splash, 가족여행, 가족 액티비티, 플랫폼, 리뉴얼" />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <AboutUsWrapper>
        {/* 회사 소개 Section */}
        <TopSection>
          <TopContent>
            <SectionTitle>회사 소개</SectionTitle>
            <TopParagraph>
              Splash는 가족 여행과 액티비티 시장을 혁신하는 가족 중심의 종합 액티비티 플랫폼입니다.
              만 4세에서 10세 사이 자녀를 둔 가족을 주요 고객으로 하여, 부모와 아이 모두가 만족할 수 있는 맞춤형 여행 및 여가 경험을 제공합니다.
            </TopParagraph>

            <TopParagraph>
              기존 가족 여행 시장은 정보의 분산과 복잡한 예약 절차로 인해 소비자들에게 큰 불편함을 초래해왔습니다.
              Splash는 이러한 문제를 해결하기 위해 여행, 레저, 교육, 체험을 포함한 다양한 가족 중심 액티비티를 한 곳에서 비교하고 예약할 수 있는 통합 플랫폼을 제공합니다.
            </TopParagraph>

            <TopParagraph>
              이를 통해 고객은 간편하고 효율적으로 가족 여행을 계획할 수 있으며,
              안전하고 신뢰할 수 있는 맞춤형 액티비티를 손쉽게 즐길 수 있습니다.
            </TopParagraph>
          </TopContent>
        </TopSection>

        <BlueInfoSection>
          <BlueInfoContainer>
            <ParentInfoBox>
              <h3>부모를 위한</h3>
              <p>
                간편하고 직관적인 예약 시스템 제공<br />
                여러 플랫폼 간 가격 비교를 통해 합리적인 선택<br />
                안전하고 신뢰할 수 있는 상품 정보 제공
              </p>
            </ParentInfoBox>
            <ChildInfoBox>
              <h3>아이를 위한</h3>
              <p>
                창의력과 흥미를 자극하는 재미있고 안전한 체험 프로그램<br />
                부모가 안심하고 참여할 수 있는 환경 조성
              </p>
            </ChildInfoBox>
          </BlueInfoContainer>
        </BlueInfoSection>

        {/* Vision & Goals Section */}
        <VisionGoalsSection>
          <InnerContainer>
            <VisionTitle>비전과 목표</VisionTitle>
            <WhiteVisionContainer>
              <HighlightTitle>가족과의 한 순간을 더욱 특별하게</HighlightTitle>
              <VisionParagraph>소비자 만족과 파트너사의 성장 지원</VisionParagraph>
              <VisionParagraph>
                Splash는 가족의 소중한 순간을 특별하게 만드는 글로벌 플랫폼으로 성장하고자 합니다.
                파트너사와 협력하여 시장 내 가치 사슬을 강화하고, 상호 성장을 위한 생태계를 구축하고자 합니다.
                이를 통해 고객이 다양한 액티비티를 간편하게 검색, 비교, 예약할 수 있는 통합 플랫폼을 구현하고,
                가족 액티비티 시장 내 정보 비대칭 문제를 해결하여 고객만족도를 극대화합니다.
              </VisionParagraph>
            </WhiteVisionContainer>

            <GoalsTitle>목표</GoalsTitle>
            <GoalsParagraph>
              통합 플랫폼 구축 및 수익성 극대화
            </GoalsParagraph>
            <GoalsParagraph>
              Splash는 파트너사와 함께 고객이 다양한 액티비티를 간편하게 검색, 비교, 예약할 수 있는 통합 플랫폼 구축을 목표로 합니다.
              시장 내 정보 비대칭 문제를 해결하여 고객 만족도를 높이는 동시에, 파트너사의 수익성을 극대화합니다.
            </GoalsParagraph>

            <StrategyContainer>
              <StrategyBox>
                <h5>신규 고객확보</h5>
                <p>더 많은 가족 고객 유입</p>
              </StrategyBox>
              <StrategyBox>
                <h5>통합 플랫폼 구축</h5>
                <p>검색, 비교, 예약이 한번에</p>
              </StrategyBox>
              <StrategyBox>
                <h5>비용효율성</h5>
                <p>운영비 절감 및 효율 극대화</p>
              </StrategyBox>
              <StrategyBox>
                <h5>브랜드노출강화</h5>
                <p>고객의 인지도 상승</p>
              </StrategyBox>
              <StrategyBox>
                <h5>시장가치확산</h5>
                <p>파트너사의 성장 촉진</p>
              </StrategyBox>
            </StrategyContainer>

          </InnerContainer>
        </VisionGoalsSection>
      </AboutUsWrapper>
    </>
  );
}

export default AboutUs;
